import React from "react";
import Card from "react-bootstrap/Card";

function ServicesCards(props) {
  return (
    <Card className="services-card-view">
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify", lineHeight: "1.7rem", fontSize: "1rem" }}>
          {props.description}
        </Card.Text>
        {"\n"}
        {"\n"}

        {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}


      </Card.Body>
    </Card >
  );
}
export default ServicesCards;
