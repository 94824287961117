import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AiFillGithub, AiOutlineTwitter } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { TbBrandFiverr } from "react-icons/tb";
import { SiUpwork } from "react-icons/si";
import { RiInstagramFill } from "react-icons/ri";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer font-bold">
      <Row>
        <Col md="6" className="footer-copywright">
          <h3>Designed and Developed by Mubbasher Yasin {year}</h3>
        </Col>

        <Col md="6" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="https://fiverr.com/mubbashiryasin"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TbBrandFiverr />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.upwork.com/freelancers/~01f0949d7df89ab9ed"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SiUpwork />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://github.com/MuBBaSher-YaSiN"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillGithub />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://twitter.com/MubbasherYas"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineTwitter />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.linkedin.com/in/mubbasher-yasin/"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.instagram.com/mubbasher_yasin/"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <RiInstagramFill />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
