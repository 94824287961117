import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ServicesCards";
import Particle from "../Particle";
import ecommerce_website from "../../Assets/Projects/ecommerce_website.png";
import dinmunte from "../../Assets/Projects/dinmunte.mp4"
import diniubire from "../../Assets/Projects/diniubire.webm"
import baroque_ecommerce_website from "../../Assets/Projects/baroque_ecommerce_website.mp4"
import admin_dashboard from "../../Assets/Projects/admin_dashboard.mp4"
import business_store from "../../Assets/Projects/business_store.png";
import tembekasua from "../../Assets/Projects/tembekasua.png";
import ecommerce_website_2 from "../../Assets/Projects/ecommerce_website_2.jpg";
import admin_dashboard_mainImage from "../../Assets/Projects/admin_dashboard.jpg"
import diniubire_mainImage from "../../Assets/Projects/diniubire_mainImage.jpg"
import agriculture_multivendor_mainImage from "../../Assets/Projects/agriculture_multivendor_mainImage.webp"
import agricultureMultivendor from "../../Assets/Projects/agricultureMultivendor.mp4"

function Services() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Unlock Your <strong className="purple">Digital Potential </strong>
        </h1>
        <p style={{ color: "white" }}>
          Your One-Stop Solution for Web Development and SEO Services.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={ecommerce_website_2}
              isBlog={false}
              title="Web Applications"
              description={
                `Elevate your online experience with our comprehensive Full Stack Web Development services!
    - Hire Full Stack Web Developer
    - Custom Website Solutions
    - High Security Websites
    - Scalable Website Architecture
    - Database Design and Management
    - Api Integration Services
    - Responsive Website Design Services
    - Website Performance Optimization
    - Advanced Coding Websites
    - Innovative Technology Integration`.split('\n').map((line, index) => <div key={index}>{line}</div>)
              }
              ghLink="https://github.com/MuBBaSher-YaSiN/Baroque-Frontend-Project"
              demoLink={baroque_ecommerce_website}
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={diniubire_mainImage}
              isBlog={false}
              title="PSD to HTML"
              description={
                `Transform your PSD or Figma designs into functional HTML or React websites. 
                - Figma to HTML
    - PSD to HTML Conversion Services
    - PSD to React and Next.js
    - Figma to Next JS
    - Figma to React JS
    - Pixel Perfect Design
    - SEO-Friendly Website Design
    - WordPress Speed Optimization Service
    - Web Accessibility Standards
    - Mobile Responsiveness`.split('\n').map((line, index) => <div key={index}>{line}</div>)
              }

              ghLink="https://diniubire.com/"
              demoLink={diniubire}
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={agriculture_multivendor_mainImage}
              isBlog={false}
              title="SEO Services"
              description={
                `Elevate your online visibility with my comprehensive SEO services. 
    - In-Depth Keyword Research
    - Strategic On-Page SEO
    - Authoritative Off-Page SEO
    - Detailed Technical SEO Audits
    - Local SEO Implementation
    - Content Optimization Techniques
    - Insightful SEO Analytics and Reporting
    - Organic Traffic Growth
    - Enhanced User Engagement
    - Conversion Rate Optimization`.split('\n').map((line, index) => <div key={index}>{line}</div>)
              }
              ghLink="/"
              demoLink={agricultureMultivendor}
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={admin_dashboard_mainImage}
              isBlog={false}
              title="Specialized Solutions"
              description={
                `For clients with distinct needs, I provide specialized web solutions across diverse domains.
    - Custom LMS Design and Development
    - Innovative Online Food Ordering Solutions
    - Real Estate Web Development and Design
    - Comprehensive Event Booking Systems
    - Tailored Subscription Services Solutions
    - Interactive Community Forums Creation
    - Exclusive Membership Sites Development
    - User-Friendly Interface Design
    - SEO-Optimized Web Solutions
    - Advanced Feature Integration
    - High-Performance Website Optimization
    - Secure and Scalable Web Architectures`.split('\n').map((line, index) => <div key={index}>{line}</div>)
              }
              ghLink="https://infypos.infyom.com/"
              demoLink={admin_dashboard}
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={business_store}
              isBlog={false}
              title="Web Mastery Coach"
              description={
                `    - Comprehensive instruction in HTML, CSS, JavaScript, TypeScript, React Js, Bootstrap, Tailwind, Node.js, Express.js, MongoDB, SQL, and Firebase
    - Implementation of cutting-edge, SEO-friendly web development techniques
    - Personalized learning plans focusing on coding best practices and effective SEO strategies
    - Guidance on real-world projects, promoting innovation and problem-solving skills
    - Mastery in effective coding and SEO implementation for achieving online success`.split('\n').map((line, index) => <div key={index}>{line}</div>)
              }
              ghLink="https://tembekasua.com/"
              demoLink={tembekasua}
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={ecommerce_website}
              isBlog={false}
              title="Website Development"
              description={
                `
    - WordPress Speed Optimization Service
    - Mobile First Responsive Design
    - WordPress Plugin Development Services
    - Website Maintenance Packages
    - High-Performance Web Architecture
    - User Engagement Strategies
    - User Interface Website Design
    - Code Efficiency
    - Shopify Website Development
    - Woocommerce Development Services
    - Payment Gateway Integration Services
    - Multi Vendor Ecommerce Website
    - Dropshipping Website
    - Service Inventory Management`.split('\n').map((line, index) => <div key={index}>{line}</div>)
              }
              ghLink="https://dinmunte.ro/"
              demoLink={dinmunte}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Services;
