import React from "react";
import "../../styles/Contact.css"
import Button from "react-bootstrap/Button";
import { Row, Col } from "react-bootstrap";
import { AiFillPhone } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";
import { FiMail } from "react-icons/fi"
import { TbBrandFiverr } from "react-icons/tb"
import { SiUpwork } from "react-icons/si";
import { AiFillGithub, AiOutlineTwitter } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import "./Contact.css";

export default function Contact() {
  return (
    <>
      <div className="main_container">
        {/* Headline */}
        <h1 className="contact_headline">For pricing or inquiries, please contact me using any of the options below:</h1>
        {/* Call Dialogue Start */}
        <div className="call_container">
          <div className="free_call_text_container">
            <p className="free_call_text">TAKE A FREE CALL</p>
          </div>
          <div>
            <a
              href="tel: +923074079459"
              //   target="_blank"
              rel="noreferrer"
              style={{ color: "white", textDecoration: "none" }}
              title="+92 307 4079459"
            >
              <Button className="phone_call_button" style={{ display: "flex", alignItems: "center" }}>
                <span style={{ paddingRight: "10px", display: "flex", alignItems: "center" }}>
                  <AiFillPhone />
                </span>
                CALL NOW
              </Button>
            </a>
          </div>
        </div>

        {/* Email Dialogue Start */}
        <div className="call_container">
          <div className="free_call_text_container">
            <p className="free_call_text">SEND AN EMAIL NOW</p>
          </div>
          <div>
            <a
              href="mailto:mubbasheryasin125@gmail.com?subject= Need a website"
              //   target="_blank"
              rel="noreferrer"
              style={{ color: "white", textDecoration: "none" }}
              title="mubbasheryasin125@gmail.com"
            >
              <Button className="phone_call_button" style={{ display: "flex", alignItems: "center" }}>
                <span style={{ paddingRight: "10px", display: "flex", alignItems: "center" }}>
                  <FiMail />
                </span>
                EMAIL NOW
              </Button>
            </a>
          </div>
        </div>

        {/* Whatsapp Message Dialogue Start */}
        <div className="call_container">
          <div className="free_call_text_container">
            <p className="free_call_text">CONTACT ON WHATSAPP</p>
          </div>
          <div>
            <a
              href="https://wa.me/923074079459/?text=Hey! I Need A Website. Can You Help Me?"
              //   target="_blank"
              rel="noreferrer"
              style={{ color: "white", textDecoration: "none" }}
              title="+92 307 4079459"
            >
              <Button className="phone_call_button" style={{ display: "flex", alignItems: "center" }}>
                <span style={{ paddingRight: "10px", display: "flex", alignItems: "center" }}>
                  <BsWhatsapp />
                </span>
                INBOX NOW
              </Button>
            </a>
          </div>
        </div>


        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons mb-3">
                <a
                  href="https://fiverr.com/mubbashiryasin"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <TbBrandFiverr />
                </a>
              </li>
              <li className="social-icons mb-3">
                <a
                  href="https://www.upwork.com/freelancers/~01f0949d7df89ab9ed"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <SiUpwork />
                </a>
              </li>
              <li className="social-icons mb-3">
                <a
                  href="https://github.com/MuBBaSher-YaSiN"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons mb-3">
                <a
                  href="https://twitter.com/MubbasherYas"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons mb-3">
                <a
                  href="https://www.linkedin.com/in/mubbasher-yasin/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons mb-3">
                <a
                  href="https://www.instagram.com/mubbasher_yasin/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <RiInstagramFill />
                </a>
              </li>
            </ul>
          </Col>
        </Row>

      </div >



    </>
  );
}
