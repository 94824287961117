import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Mubbasher Yasin </span>
            from <span className="purple"> Lahore, Pakistan.</span>
            <br />I am a Wordpress, Shopify and Full-Stack Developer.
            <br />
            <br />
            <h5 className="purple">Beyond the World of Code</h5>
            My life isn’t all about zeros and ones. Here’s a peek into my world beyond the screen:
          </p>
          <ul >
            <li className="about-activity">
              <ImPointRight /> Engage in Gaming Expeditions
            </li>
            <li className="about-activity">
              <ImPointRight /> Building Informative and Inspiring Content
            </li>
            <li className="about-activity">
              <ImPointRight /> Travel and Discover the Wonders of My Homeland!
            </li>
          </ul>
          <h5 className="purple">My Developer’s Motto</h5>
          <p style={{ color: "rgb(155 126 172)" }}>
            "Code for impact and create meaningful digital experiences!"{" "}
          </p>
          <footer className="blockquote-footer">Mubbasher</footer>
        </blockquote>
      </Card.Body>
    </Card >
  );
}

export default AboutCard;
